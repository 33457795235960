<script setup lang="ts">
import type { NuxtError } from '#app';

interface Props {
    error: NuxtError;
}
const props = defineProps<Props>();
const { error } = toRefs(props);

const handleError = () => clearError({ redirect: '/' });

useHead({
    title: `${error?.value?.statusCode} - TEXCEL`,
});
</script>

<template>
    <div>
        <NuxtLayout>
            <div class="flex flex-col justify-center items-center">
                <h2 class="text-4xl font-bold text-center dark:text-white mb-2">{{ error?.statusCode }} Error</h2>
                <p class="text-center dark:text-white mb-5">{{ error?.statusMessage }}</p>
                <button
                    type="button"
                    class="text-white bg-primary-500 hover:bg-primary-600 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-lg px-5 py-2.5 focus:outline-none dark:focus:ring-primary-800"
                    @click="handleError"
                >
                    กลับสู่หน้าแรก
                </button>
            </div>
        </NuxtLayout>
    </div>
</template>
