export default defineNuxtPlugin((nuxtApp) => {
    const pageLoading = ref<boolean>(false);
    nuxtApp.hook('page:loading:start', () => {
        pageLoading.value = true
    })

    nuxtApp.hook('page:finish', () => {
        pageLoading.value = false

    })

    return {
        provide: {
            pageLoading
        }
    }
})