import { defineNuxtPlugin } from '#app/nuxt'
import { LazyActionAbleImage, LazyAddButton, LazyAddImageButton, LazyContextMenu, LazyCreateCustomerModal, LazyCustomerMetadataCard, LazyEditContact, LazyEditCustomerModal, LazyFileModal, LazyFileGridFileImage, LazyFileTableView, LazyFileManager, LazyFilterOptions, LazyFilterSortBy, LazyGroupBy, LazyGroupByOptions, LazyInteractImage, LazyLoadingScreen, LazyLoadingSpinner, LazyNoteBlock, LazyOptionsBar, LazyOptionsBarButton, LazyPackageSelector, LazyPackageSelectorItem, LazyPaginateBar, LazyQrcodeReader, LazySearchBar, LazySkeletonComponent, LazySortByOption, LazyVoidMd, LazyVoidMr, LazyVoidSequencePR, LazyLoginModal, LazyProseA, LazyProseBlockquote, LazyProseCode, LazyProseEm, LazyProseH1, LazyProseH2, LazyProseH3, LazyProseH4, LazyProseH5, LazyProseH6, LazyProseHr, LazyProseImg, LazyProseLi, LazyProseOl, LazyProseP, LazyProsePre, LazyProseScript, LazyProseStrong, LazyProseTable, LazyProseTbody, LazyProseTd, LazyProseTh, LazyProseThead, LazyProseTr, LazyProseUl, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["ActionAbleImage", LazyActionAbleImage],
["AddButton", LazyAddButton],
["AddImageButton", LazyAddImageButton],
["ContextMenu", LazyContextMenu],
["CreateCustomerModal", LazyCreateCustomerModal],
["CustomerMetadataCard", LazyCustomerMetadataCard],
["EditContact", LazyEditContact],
["EditCustomerModal", LazyEditCustomerModal],
["FileModal", LazyFileModal],
["FileGridFileImage", LazyFileGridFileImage],
["FileTableView", LazyFileTableView],
["FileManager", LazyFileManager],
["FilterOptions", LazyFilterOptions],
["FilterSortBy", LazyFilterSortBy],
["GroupBy", LazyGroupBy],
["GroupByOptions", LazyGroupByOptions],
["InteractImage", LazyInteractImage],
["LoadingScreen", LazyLoadingScreen],
["LoadingSpinner", LazyLoadingSpinner],
["NoteBlock", LazyNoteBlock],
["OptionsBar", LazyOptionsBar],
["OptionsBarButton", LazyOptionsBarButton],
["PackageSelector", LazyPackageSelector],
["PackageSelectorItem", LazyPackageSelectorItem],
["PaginateBar", LazyPaginateBar],
["QrcodeReader", LazyQrcodeReader],
["SearchBar", LazySearchBar],
["SkeletonComponent", LazySkeletonComponent],
["SortByOption", LazySortByOption],
["VoidMd", LazyVoidMd],
["VoidMr", LazyVoidMr],
["VoidSequencePR", LazyVoidSequencePR],
["LoginModal", LazyLoginModal],
["ProseA", LazyProseA],
["ProseBlockquote", LazyProseBlockquote],
["ProseCode", LazyProseCode],
["ProseEm", LazyProseEm],
["ProseH1", LazyProseH1],
["ProseH2", LazyProseH2],
["ProseH3", LazyProseH3],
["ProseH4", LazyProseH4],
["ProseH5", LazyProseH5],
["ProseH6", LazyProseH6],
["ProseHr", LazyProseHr],
["ProseImg", LazyProseImg],
["ProseLi", LazyProseLi],
["ProseOl", LazyProseOl],
["ProseP", LazyProseP],
["ProsePre", LazyProsePre],
["ProseScript", LazyProseScript],
["ProseStrong", LazyProseStrong],
["ProseTable", LazyProseTable],
["ProseTbody", LazyProseTbody],
["ProseTd", LazyProseTd],
["ProseTh", LazyProseTh],
["ProseThead", LazyProseThead],
["ProseTr", LazyProseTr],
["ProseUl", LazyProseUl],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
