import isEmpty from 'lodash/isEmpty';

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();

    return {
        provide: {
            api: (path: string = ''): string => {
                if (!path) return path;

                const isFullUrlPath = path.startsWith('http') || path.startsWith('https');
                if (isEmpty(path) || isFullUrlPath) return path;

                return config.public.api + path;
            },
            appName: config.public.appName,
            orderUrl: config.public.orderUrl,
        },
    };
});
