import Hotjar from '@hotjar/browser';
// EIEI
export default defineNuxtPlugin(() => {
    const siteId = 4952792;
    const hotjarVersion = 6;

    return {
        provide: {
            hotjar: () => {
                Hotjar.init(siteId, hotjarVersion);
            },
        },
    };
});
