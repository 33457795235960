import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', () => {
    const { data: user, status } = useAuth();
    const isLoggedIn = status.value === 'authenticated';
    const redirectTo = ref<string>('/');

    // ROLE CHECK
    const isEmployee = computed(() => user.value?.actionRole.includes('EMPLOYEE'));
    const isAccount = computed(() => isEmployee.value && user.value?.actionRole.includes('ACCOUNT'));
    const isAdminQuotation = computed(() => isEmployee.value && user.value?.actionRole.includes('ADMIN_QUOTATION'));
    const isGraphic = computed(() => isEmployee.value && user.value?.actionRole.includes('GRAPHIC'));
    const isMerchandiser = computed(() => isEmployee.value && user.value?.actionRole.includes('MERCHANDISER'));
    const isProduction = computed(() => isEmployee.value && user.value?.actionRole.includes('PRODUCTION'));
    const isSale = computed(() => isEmployee.value && user.value?.actionRole.includes('SALE'));
    const isStore = computed(() => isEmployee.value && user.value?.actionRole.includes('STORE'));
    const isSuperAdmin = computed(() => user.value?.actionRole.includes('SUPERADMIN'));
    const isManager = computed(() => user.value?.isManager);

    return {
        user: useAuth().data.value,
        redirectTo,
        isLoggedIn,
        isEmployee,
        isAccount,
        isAdminQuotation,
        isGraphic,
        isMerchandiser,
        isProduction,
        isSale,
        isStore,
        isSuperAdmin,
        isManager,
    };
});
