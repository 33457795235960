import validate from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/runner/work/texcel-web/texcel-web/middleware/auth.global.ts";
import manifest_45route_45rule from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  manager: () => import("/home/runner/work/texcel-web/texcel-web/middleware/manager.ts"),
  store: () => import("/home/runner/work/texcel-web/texcel-web/middleware/store.ts"),
  "super-admin": () => import("/home/runner/work/texcel-web/texcel-web/middleware/superAdmin.ts"),
  auth: () => import("/home/runner/work/texcel-web/texcel-web/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}