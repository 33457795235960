<script setup lang="ts">
import { Toaster } from 'vue-sonner';
import { useAuthStore } from '@/stores/useAuthStore';
import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';

// LAZY COMPONENT
const LoginModal = defineAsyncComponent(() => import('./components/global/loginModal.vue'));
const SetupModal = defineAsyncComponent(() => import('@/components/setup/setupModal.vue'));

// META
useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - TEXCEL` : 'TEXCEL';
    },
});

// VARIABLE
const toastOptions = ref({
    style: { fontFamily: 'IBM Plex Sans Thai', fontSize: '1rem' },
});
const { $hotjar, $pageLoading } = useNuxtApp();
const auth = useAuthStore();
const { status } = useAuth();
const openLoginModalState = ref<boolean>(false);
const openSetupModalState = ref<boolean>(true);
const { $api } = useNuxtApp();
const authService = useAuth();

// COMPUTED
const token = computed<string>(() => {
    if (!authService.token.value) return '';

    return authService.token.value?.split(' ')[1];
});

// SOCKET
const socket = io($api('/notification'), {
    path: '/notification',
    withCredentials: true,
    query: {
        token: token.value,
    },
    transports: ['websocket'],
});

// COMPUTED
const isUnauthenticated = computed<boolean>(() => status.value === 'unauthenticated' && useRoute().path !== '/login');
const setupCompleted = computed(() => auth.user && auth.user.isCompletedSetup);

// WATCH
watch(isUnauthenticated, () => {
    openLoginModalState.value = isUnauthenticated.value;
});

// PROVIDE
provide<Socket>('notificationSocket', socket);

// ON MOUNTED
onMounted(() => {
    // INIT HOTJAR
    $hotjar();
});
</script>
<template>
    <div>
        <NuxtPage />
        <ClientOnly>
            <Toaster position="top-right" :toast-options="toastOptions" :duration="30000" rich-colors close-button />
        </ClientOnly>

        <Suspense v-if="!setupCompleted && auth.isLoggedIn">
            <SetupModal v-model:open="openSetupModalState" />
            <template #fallback>
                <div class="w-screen h-screen bg-black/60"></div>
            </template>
        </Suspense>

        <Suspense v-if="isUnauthenticated">
            <LoginModal v-model:open="openLoginModalState"></LoginModal>
            <template #fallback>
                <div class="w-screen h-screen bg-black/60"></div>
            </template>
        </Suspense>
        <LoadingScreen v-if="$pageLoading" />
    </div>
</template>

<style>
@import url('~/assets/css/tailwind.css');
</style>
