import payload_plugin_qLmFnukI99 from "/home/runner/work/texcel-web/texcel-web/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/texcel-web/texcel-web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/texcel-web/texcel-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_4F1kZh3YAB from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_I4dbrL2rYz from "/home/runner/work/texcel-web/texcel-web/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import components_client_ZB1W0EeH0E from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import plugin_w2VlvAEcpa from "/home/runner/work/texcel-web/texcel-web/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_ghbUAjaD3n from "/home/runner/work/texcel-web/texcel-web/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_OVoKJro5pc from "/home/runner/work/texcel-web/texcel-web/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_tMGwffvjBc from "/home/runner/work/texcel-web/texcel-web/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_AUP22rrBAc from "/home/runner/work/texcel-web/texcel-web/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import env_5Pbv7skZPL from "/home/runner/work/texcel-web/texcel-web/plugins/env.ts";
import hotjar_pBWYs5JQbk from "/home/runner/work/texcel-web/texcel-web/plugins/hotjar.ts";
import imageUrl_1nC9NDTPTt from "/home/runner/work/texcel-web/texcel-web/plugins/imageUrl.ts";
import loading_GxQ2Z9Goxa from "/home/runner/work/texcel-web/texcel-web/plugins/loading.ts";
import multiselectClass_hPcAK2rwtb from "/home/runner/work/texcel-web/texcel-web/plugins/multiselectClass.ts";
import sonner_client_l6WBUspFNo from "/home/runner/work/texcel-web/texcel-web/plugins/sonner.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_4F1kZh3YAB,
  plugin_I4dbrL2rYz,
  components_client_ZB1W0EeH0E,
  plugin_w2VlvAEcpa,
  plugin_ghbUAjaD3n,
  plugin_client_OVoKJro5pc,
  plugin_tMGwffvjBc,
  plugin_AUP22rrBAc,
  env_5Pbv7skZPL,
  hotjar_pBWYs5JQbk,
  imageUrl_1nC9NDTPTt,
  loading_GxQ2Z9Goxa,
  multiselectClass_hPcAK2rwtb,
  sonner_client_l6WBUspFNo
]